// Here you can add other styles
.box {
  width: 200px;
  padding: 10px;
  background-color: lightgray;
  cursor: pointer;
}

.box-content {
  margin-bottom: 10px;
}

.dropdown {
  background-color: white;
  border: 1px solid gray;
  padding: 10px;
}

.dropdown ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.dropdown li {
  margin-bottom: 5px;
}

.dropdown a {
  color: black;
  text-decoration: none;
}

.open {
  background-color: lightblue;
}

input[type="text"] {
  width: 100%;
  border: 2px solid #aaa;
  border-radius: 4px;
  margin: 8px 0;
  outline: none;
  padding: 8px;
  box-sizing: border-box;
  transition: 0.3s;
  background: #d4cfcf;
}

input[type="text"]:focus {
  border-color: dodgerBlue;
  box-shadow: 0 0 8px 0 dodgerBlue;
}

li a {
  text-decoration: none;
  color: #2d2f31;
}

.spanCustom {
  padding: 30px;
  background: #2d2f31;
  color: white;
  font-size: 1.2em;
  font-variant: small-caps;
  cursor: pointer;
  display: block;
}

.spanCustom::after {
  float: right;
  right: 10%;
  content: "+";
}

.slide {
  clear: both;
  width: 100%;
  height: 0px;
  overflow: hidden;
  text-align: center;
  transition: height 0.4s ease;
}

.slide li {
  padding: 30px;
}

#touch {
  position: absolute;
  opacity: 0;
  height: 0px;
}

#touch:checked + .slide {
  height: 300px;
}

.incoming_msg {
  min-height: 77px;
}

.incoming_msg_img {
  display: inline-block;
  // width: 6%;
}

.inboxImage {
  height: 25px;
  width: 25px;
}

.headerImage {
  border-radius: 8px;
  border: 2px solid #a4afdb;
  height: 62px;
  width: 64px;
}
.settingsHeader {
  border-radius: 8px;
  border: 2px solid #a4afdb;
  background: #bfb4f7;
}

.received_msg {
  display: inline-block;
  padding: 0 0 0 10px;
  vertical-align: top;
  width: 97%;
}
.received_withd_msg p {
  background: #e4e8fb none repeat scroll 0 0;
  border-radius: 3px;
  color: #646464;
  font-size: 14px;
  margin: 0;
  padding: 5px 10px 5px 12px;
  width: 100%;
}
.time_date {
  color: #747474;
  display: block;
  font-size: 10px;
  margin: 3px 0 0;
}
// .received_withd_msg { width: 70%;}
.mesgs {
  float: left;
  padding: 40px;
}

.sent_msg p {
  background: #3f51b5 none repeat scroll 0 0;
  border-radius: 3px;
  font-size: 14px;
  margin: 0;
  color: #fff;
  padding: 5px 10px 5px 12px;
  width: 100%;
}
.outgoing_msg {
  overflow: hidden;
  margin: 26px 0 26px;
}
.sent_msg {
  float: right;
  // width: 70%;
  text-align: right;
}

.input_msg_write input {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  color: #4c4c4c;
  font-size: 15px;
  min-height: 48px;
  width: 100%;
}

.outgoing_msg .sent_msg .boxed {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 10px;
}

.outgoing_msg .sent_msg .boxed:hover {
  background-color: #f5f5f5;
}

.outgoing_msg .sent_msg .boxed.expanded {
  height: auto;
}

.outgoing_msg .sent_msg .boxed-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.outgoing_msg .sent_msg .boxed-header h3 {
  margin: 0;
}

.outgoing_msg .sent_msg .boxed-content {
  margin-top: 10px;
  align-items: left !important;
  display: flex;
}

.scaiChat {
  background-color: white;
  max-height: 580px;
  overflow: scroll;
}

.loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loader-logo img {
  width: 100px; /* Adjust the width of the logo as needed */
  height: auto;
}

.loader-spinner {
  margin-top: 20px;
  border: 4px solid #f3f3f3; /* Light gray border */
  border-top: 4px solid #3498db; /* Blue border on top */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

#traffic {
  text-align: left;
  padding-top: 10px;
  padding-left: 59px;
}

/*Ankita */
.categories img {
  width: 50px;
}
.deleteButton {
  float: right;
  width: 80px;
  height: 25px;
  background-color: #00c3ff;
  border-color: aqua;
  line-height: normal;
}
.uploadFile {
  width: 50%;
}
.marginLeftButton {
  margin-left: 10px;
}
.fileListConatiner {
  margin-left: 1px;
}
.fileUploadTopBar {
  display: inline-flex;
}

.file-list {
  list-style: none;
  padding: 20px 0;
}
.file-list > li:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.025);
}
.file-list > li {
  position: relative;
  padding: 12px;
  border-radius: 1px;
}
.file-list .file-control {
  float: left;
  width: 50px;
  line-height: 3em;
}
input[type="radio"].magic-radio,
input[type="checkbox"].magic-checkbox {
  position: fixed;
  z-index: -50;
  top: -2000px;
}
.magic-checkbox + label {
  min-height: 17px;
  min-width: 17px;
}
.file-list .file-details {
  display: block;
  overflow: hidden;
  margin-right: 50px;
}
.media-block:after {
  content: "";
  display: table;
  clear: both;
}
.file-list .media-block > .media-left {
  font-size: 2.5em;
  line-height: 1.2em;
}
.media-block .media-left {
  display: block;
  float: left;
}
.media-body,
.media-left,
.media-right {
  display: table-cell;
  vertical-align: top;
}
.media-left,
.media > .pull-left {
  padding-right: 10px;
}
[class^="demo-pli-"],
[class*=" demo-pli-"],
[class^="demo-psi-"],
[class*=" demo-psi-"] {
  font-family: "nifty-demo-icons" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.file-list .media-block .media-body {
  vertical-align: middle;
}
.media-block .media-body {
  display: block;
  overflow: hidden;
  width: auto;
}
.media-body,
.media-left,
.media-right {
  display: table-cell;
  vertical-align: top;
}
.media,
.media-body {
  overflow: hidden;
  zoom: 1;
}
.file-list .file-name.single-line {
  line-height: 2.5em;
}
.file-list .file-name {
  color: #4d627b;
  margin-bottom: 0;
  font-weight: 700;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/*Dropdown*/
span.msg,
span.choose {
  color: #555;
  padding: 5px 0 10px;
  display: inherit;
}
.container {
  width: 500px;
  margin: 50px auto 0;
  text-align: center;
}

/*Styling Selectbox*/
.dropdown {
  width: 300px;
  display: inline-block;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 0 2px rgb(204, 204, 204);
  transition: all 0.5s ease;
  position: relative;
  font-size: 14px;
  color: #474747;
  height: 100%;
  text-align: left;
}
.dropdown .select {
  cursor: pointer;
  display: block;
  padding: 10px;
}
.dropdown .select > i {
  font-size: 13px;
  color: #888;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  float: right;
  line-height: 20px;
}
.dropdown:hover {
  box-shadow: 0 0 4px rgb(204, 204, 204);
}
.dropdown:active {
  background-color: #f8f8f8;
}
.dropdown.active:hover,
.dropdown.active {
  box-shadow: 0 0 4px rgb(204, 204, 204);
  border-radius: 2px 2px 0 0;
  background-color: #f8f8f8;
}
.dropdown.active .select > i {
  transform: rotate(-90deg);
}
.dropdown .dropdown-menu {
  position: absolute;
  background-color: #fff;
  width: 100%;
  left: 0;
  margin-top: 1px;
  box-shadow: 0 1px 2px rgb(204, 204, 204);
  border-radius: 0 1px 2px 2px;
  overflow: hidden;
  display: none;
  max-height: 144px;
  overflow-y: auto;
  z-index: 9;
}
.dropdown .dropdown-menu li {
  padding: 10px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.dropdown .dropdown-menu {
  padding: 0;
  list-style: none;
}
.dropdown .dropdown-menu li:hover {
  background-color: #f2f2f2;
}
.dropdown .dropdown-menu li:active {
  background-color: #e2e2e2;
}

/*Dropdown*/

.websiteInput {
  padding-left: 10px;
}

.header-nav .show > .nav-link,
.header-nav .nav-link.active {
  color: var(--cui-header-active-color);
  font-size: x-large;
}

.foldersList {
  display: inline-block;
}

.contentManagement {
  display: flex;
}

/*HEADER CSS*/
/* Navigation Bar */
.navbar {
  background-color: #f2f2f2;
  padding: 10px;
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100%;
  margin-bottom: 0px;
}

.navbar-logo {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.logo-image {
  width: 240px;
  height: 40px;
  margin-right: 10px;
}

.navbar-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  padding-top: 8px;
  display: flex;
  align-items: center;
}

.navbar-item {
  display: inline-block;
  margin-right: 10px;
}

.navbar-item-right {
  margin-left: auto;
  padding-right: 8rem;
}

.navbar-link {
  color: #333;
  text-decoration: none;
  font-weight: bold;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.navbar-link:hover {
  color: #ffffff;
  background-color: #00c3ff;
}

.navbar-item.active .navbar-link {
  background-color: #00c3ff;
  color: #ffffff;
}

/*SCAI Buttons*/
.button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.button {
  padding: 10px 20px;
  border: none;
  background-color: #f2f2f2;
  color: #333;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #ccc;
}

.button.active {
  background-color: #333;
  color: #fff;
}

$scrollHeight: 7px;
::-webkit-scrollbar {
  background: transparent;
  width: 7px;
  height: $scrollHeight;
}
::-webkit-scrollbar-button {
  display: none;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-track-piece {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
}
// table tbody {
//   display: block;
//   max-height: 300px;
//   overflow-y: scroll;
// }
// table thead, table tbody tr {
//   display: table;
//   width: 100%;
//   table-layout: fixed;
// }

/*FILELIST*/
.file-list-abc {
  // max-width: 600px;
  // margin: 50px auto;
  // padding: 20px;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  width: 16%;
  padding: 2rem;
}
.file-list-abc ul {
  list-style-type: none;
  padding: 0;
}

.file-list-abc h6 {
  // box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #f3a0a0 0 -1px 9px, rgb(207 159 159 / 50%) 0 9px 27px;
  margin-top: 0;
  font-size: 24px;
  color: #333;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  display: inline-flex;
  // padding-right: 60px;
  // padding-left: 60px;
  max-width: inherit;
}
.file-list-abc li {
  margin-bottom: 10px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
}

.file-list-abc div {
  text-decoration: none;
  color: #007bff;
}

.file-list-abc div:hover {
  text-decoration: underline;
}
/**/

.dashboardTable {
  margin-left: "0px" !important;
}

/*PROJECT COLOR ICON RED GREEN*/

.led-box {
  height: 30px;
  width: 5rem;
  margin: 10px 0;
  float: left;
}

.led-box p {
  font-size: 12px;
  text-align: center;
  margin: 1em;
}

.led-red {
  margin: 0 auto;
  width: 24px;
  height: 24px;
  background-color: #f00;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px,
    rgba(255, 0, 0, 0.5) 0 2px 12px;
  // -webkit-animation: blinkRed 0.5s infinite;
  // -moz-animation: blinkRed 0.5s infinite;
  // -ms-animation: blinkRed 0.5s infinite;
  // -o-animation: blinkRed 0.5s infinite;
  // animation: blinkRed 0.5s infinite;
}

// @-webkit-keyframes blinkRed {
//     from { background-color: #F00; }
//     50% { background-color: #A00; box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 0;}
//     to { background-color: #F00; }
// }
// @-moz-keyframes blinkRed {
//     from { background-color: #F00; }
//     50% { background-color: #A00; box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 0;}
//     to { background-color: #F00; }
// }
// @-ms-keyframes blinkRed {
//     from { background-color: #F00; }
//     50% { background-color: #A00; box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 0;}
//     to { background-color: #F00; }
// }
// @-o-keyframes blinkRed {
//     from { background-color: #F00; }
//     50% { background-color: #A00; box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 0;}
//     to { background-color: #F00; }
// }
// @keyframes blinkRed {
//     from { background-color: #F00; }
//     50% { background-color: #A00; box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 0;}
//     to { background-color: #F00; }
// }

.led-yellow {
  margin: 0 auto;
  width: 24px;
  height: 24px;
  background-color: #ff0;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #808002 0 -1px 9px,
    #ff0 0 2px 12px;
  // -webkit-animation: blinkYellow 1s infinite;
  // -moz-animation: blinkYellow 1s infinite;
  // -ms-animation: blinkYellow 1s infinite;
  // -o-animation: blinkYellow 1s infinite;
  // animation: blinkYellow 1s infinite;
}

// @-webkit-keyframes blinkYellow {
//     from { background-color: #FF0; }
//     50% { background-color: #AA0; box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #808002 0 -1px 9px, #FF0 0 2px 0; }
//     to { background-color: #FF0; }
// }
// @-moz-keyframes blinkYellow {
//     from { background-color: #FF0; }
//     50% { background-color: #AA0; box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #808002 0 -1px 9px, #FF0 0 2px 0; }
//     to { background-color: #FF0; }
// }
// @-ms-keyframes blinkYellow {
//     from { background-color: #FF0; }
//     50% { background-color: #AA0; box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #808002 0 -1px 9px, #FF0 0 2px 0; }
//     to { background-color: #FF0; }
// }
// @-o-keyframes blinkYellow {
//     from { background-color: #FF0; }
//     50% { background-color: #AA0; box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #808002 0 -1px 9px, #FF0 0 2px 0; }
//     to { background-color: #FF0; }
// }
// @keyframes blinkYellow {
//     from { background-color: #FF0; }
//     50% { background-color: #AA0; box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #808002 0 -1px 9px, #FF0 0 2px 0; }
//     to { background-color: #FF0; }
// }

.led-green {
  margin: 0 auto;
  width: 24px;
  height: 24px;
  background-color: #abff00;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #304701 0 -1px 9px,
    #89ff00 0 2px 12px;
}

.led-blue {
  margin: 0 auto;
  width: 24px;
  height: 24px;
  background-color: #24e0ff;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #006 0 -1px 9px,
    #3f8cff 0 2px 14px;
}

/*ANKITA*/
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);

  input {
    background-color: #ffffff;
  }

  label {
    width: 50%;
    margin-right: 20px;
  }
}

.popupUpdate {
  float: right;
  background-color: #00c3ff;
  border-color: aqua;
}
.closePopup {
  float: right;
  font-weight: bold;
}

.popup-content {
  text-align: center;
}

.popupButton {
  background-color: #56aff8;
  border: 1px solid #000;
  padding: 6px 12px;
  color: #ffffff;
}

/*ANKITA*/

/*NEW TABLE*/
@import url(https://fonts.googleapis.com/css?family=Roboto:400,700);

.container {
  max-width: 800px;
  margin: 40px auto;
  padding: 0 5%;
}

h1 {
  text-align: center;
  font-size: 3rem;
  color: rgba(255, 255, 255, 0.8);
  text-transform: uppercase;
  line-height: 1.375;
  margin: 0 0 2.4rem 0;
  letter-spacing: 1px;
}

h2 {
  text-align: right;
  font-size: 1.2rem;
  text-transform: uppercase;
  line-height: 1.375;
  margin: 0;
  letter-spacing: 1px;
}

// for invoice list (customer table)
.tips-table-style-1 {
    table {
    width: 100%;
    min-width: 300px;
    // margin-bottom: 2.4rem;
    background-color: #20262e;
    color: #fff;
    overflow: hidden;
    // overflow-y: auto;
    }

    table tr:nth-child(even) {
    background-color: rgb(46, 53, 62);
    }

    table th,
    table td:before {
    color: #28b1de;
    }

    table th {
    display: none;
    }

    table th,
    table td {
    margin: 0.5rem 2rem;
    text-align: left;
    }

    table td {
    display: block;
    font-size: 90%;
    }

    table td:first-child {
    padding-top: 1rem;
    min-width: 0;
    }

    table td:last-child {
    padding-bottom: 1rem;
    }

    table td:before {
    content: attr(data-th) ":\00a0";
    font-weight: bold;
    min-width: 8rem;
    display: inline-block;
    }

    @media (min-width: 600px) {
    table td:before {
        display: none;
    }
    table th,
    table td {
        display: table-cell;
    }
    table th,
    table td,
    table td:first-child,
    table td:last-child {
        padding: 0.5rem 2rem;
    }
    }
}

// for invoice table
#root > div.tips-table-style-2 {
    table {
    width: 100%;
    min-width: 300px;
    // margin-bottom: 2.4rem;
    background-color: #20262e;
    color: #fff;
    overflow: hidden;
    // overflow-y: auto;
    }

    table tr:nth-child(even) {
    background-color: rgb(46, 53, 62);
    }

    table th,
    table td:before {
    color: #28b1de;
    }

    table th {
    display: none;
    }

    table th,
    table td {
    text-align: left;
    }

    table td {
    display: block;
    font-size: 90%;
    }

    table td:first-child {
    padding-top: 1rem;
    min-width: 0;
    }

    table td:last-child {
    padding-bottom: 1rem;
    }

    table td:before {
    content: attr(data-th) ":\00a0";
    font-weight: bold;
    min-width: 8rem;
    display: inline-block;
    }

    @media (min-width: 600px) {
    table td:before {
        display: none;
    }
    table th,
    table td {
        display: table-cell;
    }
    table th,
    table td,
    table td:first-child,
    table td:last-child {
        padding: 0.5rem 2rem;
    }
    }
}

// for invoice list (metadata table)
.tips-table-style-3 {
    table {
    width: 100%;
    min-width: 300px;
    // margin-bottom: 2.4rem;
    background-color: #20262e;
    color: #fff;
    overflow: hidden;
    // overflow-y: auto;
    }

    table tr:nth-child(even) {
    background-color: rgb(46, 53, 62);
    }

    table th,
    table td:before {
    color: #28b1de;
    }

    table th {
    display: none;
    }

    table th,
    table td {
    text-align: left;
    }

    table td {
    display: block;
    font-size: 90%;
    }

    table td:first-child {
    padding-top: 1rem;
    min-width: 0;
    }

    table td:last-child {
    padding-bottom: 1rem;
    }

    table td:before {
    content: attr(data-th) ":\00a0";
    font-weight: bold;
    min-width: 8rem;
    display: inline-block;
    }

    @media (min-width: 600px) {
    table td:before {
        display: none;
    }
    table th,
    table td {
        display: table-cell;
    }
    table th,
    table td,
    table td:first-child,
    table td:last-child {
        padding: 0.5rem 2rem;
    }
    }
}

// for savings invoice table
.tips-table-style-4 {
    table {
    width: 100%;
    min-width: 300px;
    // margin-bottom: 2.4rem;
    background-color: #20262e;
    color: #fff;
    overflow: hidden;
    // overflow-y: auto;
    }

    table tr:nth-child(even) {
      background-color: rgb(46, 53, 62);
    }

    table tr:hover {
      background-color: lighten(rgb(46, 53, 62), 10%);
    }

    table th,
    table td:before {
    color: #28b1de;
    }

    table th {
    display: none;
    }

    table th,
    table td {
    text-align: left;
    }

    table td {
    display: block;
    font-size: 90%;
    }

    table td:first-child {
    padding-top: 1rem;
    min-width: 0;
    }

    table td:last-child {
    padding-bottom: 1rem;
    }

    table td:before {
    content: attr(data-th) ":\00a0";
    font-weight: bold;
    min-width: 8rem;
    display: inline-block;
    }

    @media (min-width: 600px) {
    table td:before {
        display: none;
    }
    table th,
    table td {
        display: table-cell;
    }
    table th,
    table td,
    table td:first-child,
    table td:last-child {
        padding: 0.5rem 2rem;
    }
    }
}

body {
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.6rem;
  line-height: 1.6;
  color: #20262e;
  background-color: #ffffff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dashboard-btn-flex-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 0.5em;

  .dshbrd-row{
    // margin-bottom: 1em;
  }
}

.updated-row {
    background-color: lightgoldenrodyellow;
}